'use client';

import { chakra, forwardRef, type HTMLChakraProps } from '@chakra-ui/react';
import { hash } from '@ikona/hash';
import type { IconName } from '@ikona/types/icon-name';
import type { Ref, SVGProps } from 'react';

export type { IconName };

export const SvgEdit = forwardRef(
   (
      {
         name,
         isChecked,
         isIndeterminate,
         ...props
      }: Omit<SVGProps<SVGSVGElement>, 'name'> & {
         name: IconName;
         isChecked?: boolean;
         isIndeterminate?: boolean;
      },
      ref: Ref<SVGSVGElement>,
   ) => (
      <svg ref={ref} {...props}>
         <use href={`/icons/sprite.${hash}.svg#${name}`} />
      </svg>
   ),
);

export type IconProps = HTMLChakraProps<'svg'> & { name: IconName };

export const Icon = chakra<typeof SvgEdit, IconProps>(SvgEdit);
